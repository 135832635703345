<style src="../css/head.css">
</style>

<template>
  <div>
    <Head-section></Head-section>

    <div class="container">
      <div class="row clearfix featurette border-bottom pb-1" v-for="(item) in items" :key="item.id">
        <div class="col-md-7">
          <a :href="'/'+lan+'/product/'+item.id">
          <b-img-lazy class="d-block img-fluid w-100" fluid :src="item.skus[item.selectIndex].images[0].path" :alt="item.description" v-if="item.skus.length>0 && item.skus[item.selectIndex].images.length>0"></b-img-lazy>
          </a>
        </div>

        <div class="col-md-5 featurette m_t1">
          <dt>
            <a :href="'/'+lan+'/product/'+item.id" class="text-dark">{{item.productname}}</a>
          </dt>
          <dt class="font-weight-light m_t1" v-if="item!=sku && item.skus[0].saveratio!=''">
            <del>
              <span>{{symble_money}}{{ (lan=='jp')?(item.skus[0].price*item.currency).toFixed(0):(item.skus[0].price*item.currency).toFixed(2) }}</span>
            </del>
            &nbsp;&nbsp;
          </dt>
          <dt class="font-weight-light m_t1" v-if="item==sku && item.skus[col_index].saveratio!=''">
            <del>
              <span>{{symble_money}}{{(lan=='jp')?(item.skus[col_index].price*item.currency).toFixed(0):(item.skus[col_index].price*item.currency).toFixed(2)}}</span>
            </del>&nbsp;&nbsp;
          </dt>
          <dt
            class="m_t1"
            v-if="item!=sku && item.skus[0].saveratio==''"
          >{{symble_money}}{{ (lan=='jp')?(item.skus[0].price*item.currency).toFixed(0):(item.skus[0].price*item.currency).toFixed(2) }}</dt>
          <dt
            class="m_t1"
            v-if="item==sku && item.skus[col_index].saveratio==''"
          >{{symble_money}}{{ (lan=='jp')?(item.skus[col_index].price*item.currency).toFixed(0):(item.skus[col_index].price*item.currency).toFixed(2) }}</dt>
          <dt class="m_t1" v-if="item!=sku && item.skus[0].saveratio!=''">
            {{symble_money}}
            {{(lan=='jp')?(item.skus[0].discountprice*item.currency).toFixed(0):(item.skus[0].discountprice*item.currency).toFixed(2)}}
            <mark
              class="red_bg_tx_pink"
            >{{item.skus[0].saveratio}}</mark>
          </dt>
          <dt class="m_t1" v-if="item==sku && item.skus[col_index].saveratio!=''">
            {{symble_money}}{{(lan=='jp')?(item.skus[col_index].discountprice*item.currency).toFixed(0):(item.skus[col_index].discountprice*item.currency).toFixed(2)}}
            <mark
              class="red_bg_tx_pink"
            >{{item.skus[col_index].saveratio}}</mark>
          </dt>
          <div class="mt-3 container row">
            <span v-if="item.skus.length>1" class="my-2">{{$t("message.COLORS")}}:</span>
            <span v-if="item.skus.length<2" class="my-2">{{$t("message.COLOR")}}:</span>

            <div class="ml-1">
              <button
                class="btn my-1"
                v-for="(sku_col,idx) in item.skus"
                :key="sku_col.id"
                :class="{'btn-outline-danger':((idx==col_index && item==sku) || (item!=sku && idx==0)),'btn-outline-dark':((idx!=0 && item!=sku) || (item==sku && idx!=col_index)),'ml-3':idx!=0}"
                @click="selCol(item,idx)"
              >{{sku_col.sku}}</button>
            </div>
          </div>
          <div v-if="item.skus[item.selectIndex].regionlimit==1" class="mb-3">
            <dt>
              <i class="fas fa-truck"></i> &nbsp;{{ $t("message.Region_limit") }}
            </dt>
          </div>
          <div>
            <b-button
              variant="success outline-dark mt-3 mx-1"
              :disabled="item.skus[item.selectIndex].regionlimit==1"
              @click="toOrdPre(item)"
            ><i class="fas fa-credit-card"></i>
            {{$t("message.Buy_now")}}</b-button>
            <b-button
              variant="primary outline-dark mt-3 mx-1"
              :disabled="item.skus[item.selectIndex].regionlimit==1"
              @click="add_to_cart(item)"
            >
              <i class="fas fa-shopping-cart"></i>
              {{$t('message.ADD_TO_CART')}}
            </b-button>
          </div>
          <div class="text-muted m_t1">{{item.description}}</div>
        </div>
      </div>
      <p class="text-center">
        <button
          class="btn btn-secondary"
          @click="getPdLsByCategry(pg,20)"
          v-if="(pg+1)<total"
        >{{$t('message.Load_More')}}</button>
      </p>
    </div>
    <div class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..." variant="success" :class="{'d-none':spin_run}"></b-spinner>
    </div>

    <b-modal
      id="modal-custom"
      ref="modal-custom"
      :title="$t('message.Customize_your_product')"
      hide-footer
    >
      <div>
        <b-form-group label="Individual radios">
          <b-form-radio v-model="selected" name="some-radios" value="1">{{$t("message.Customize")}}</b-form-radio>
          <b-form-radio v-model="selected" name="some-radios" value="0">{{$t("message.None")}}</b-form-radio>
        </b-form-group>

        <div class="mt-3 mb-3" v-if="skus!=null">
          <button class="btn btn-outline-danger">{{skus.sku}}</button>
        </div>
        <div v-if="selected==1">
          <label for="input-live">{{$t('message.Customize_message')}}</label>

          <b-form-input
            id="input-live"
            v-model="text"
            :state="nameState"
            aria-describedby="input-live-help input-live-feedback"
            :placeholder="$t('message.Customize_message')"
            trim
          ></b-form-input>

          <b-form-invalid-feedback
            id="input-live-feedback"
          >{{$t('message.You_need_fill_all_input')}}</b-form-invalid-feedback>
        </div>
        <b-img
          thumbnail
          fluid
          :src="skus.customizeImage"
          alt="Customize Product"
          class="mt-2"
          v-if="skus!=null"
        ></b-img>
        <div>
          <b-button variant="outline-primary" @click="hideModal()">{{$t("message.Cancle")}}</b-button>
          <b-button
            variant="success"
            class="ml-1 my-1"
            @click="add_to_cart_custom(skus.id,1,selected,text)"
          >{{$t("message.Sure")}}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import HeadSection from "./StoreHeadSection.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CategorySection",
  components: {
    HeadSection
  },
  data() {
    return {
      col_index: 0,
      sku: "",
      skus: null,
      selected: 1,
      items: [],
      total: 0,
      spin_run: false,
      text: "",
      is_now: 0,
      pg: 0
    };
  },
  created() {
    let language = this.$route.params.language;
    if(this.$store.state.lan_array.indexOf(language) == -1){
      this.toHome();
    }
    this.getPdLsByCategry(this.pg, 20);
  },
  computed: {
    ...mapGetters(["lan", "lan_array", "access_token", "symble_money"]),
    nameState() {
      return this.text != "" ? true : false;
    }
  },
  watch: {
    $route() {
      this.getPdLsByCategry(0, 20);
    },
    lan() {
      this.getPdLsByCategry(0, 20);
    }
  },

  methods: {
    ...mapActions({
      getPdLsByCategry(dispatch, pg, pgs) {
        this.spin_run = false;
        let ctgid = this.$route.params.categoryid;
        let tl = this.total;
        let load = 1;
        if (pg != 0 && pg + 1 >= tl) return;
        dispatch("getPdLsByCategry", {
          ctgid,
          pg,
          pgs,
          load
        }).then(data => {
          this.pg = pg + 1;
          this.spin_run = data.result;
          if (data.result) {
            this.items = data.items;
            for (let item of this.items) {
              this.$set(item, 'selectIndex', 0);
            }
            this.total = Math.ceil(data.total / 20);
          }
        });
      },

      getShopCarLs(dispatch) {
        dispatch("getShopCarLs", {}).then(data => {
          this.select_cart_ls = data.result;
          if (data.result) {
            this.$store.state.items_cart = data.items;
          }
        });
      },
      add_to_cart(dispatch, item) {
        this.is_now = 0;
        var skuid = "";
        var count = 1;
        var customized = 0;
        var customizedesc = "";
        var stock = 0;
        if (this.sku == item) {
          skuid = item.skus[this.col_index].id;
          stock = item.skus[this.col_index].stock;
          customized = item.skus[this.col_index].customize;
          this.skus = item.skus[this.col_index];
        } else {
          skuid = item.skus[0].id;
          stock = item.skus[0].stock;
          customized = item.skus[0].customize;
          this.skus = item.skus[0];
        }

        if (count > stock) {
          this.$bvToast.toast(this.$t("message.Insufficient_quantity"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }

        if (this.access_token == "" && this.lan == "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }
        if (customized) {
          this.showModal();
          return;
        }

        dispatch("add_to_cart", {
          skuid,
          count,
          customized,
          customizedesc
        }).then(data => {
          if (data.result) {
            this.getShopCarLs();
            this.$bvToast.toast(this.$t("message.Add_Success"), {
              title: ``,
              toaster: "b-toaster-top-full",
              solid: true,
              variant: "success",
              appendToast: false
            });
            return;
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
            }
          }
        });
      },
      toOrdPre(dispatch, item) {
        this.is_now = 1;
        var skuid = "";
        var count = 1;
        var customized = 0;
        var customizedesc = "";
        var stock = 0;
        if (this.sku == item) {
          skuid = item.skus[this.col_index].id;
          stock = item.skus[this.col_index].stock;
          customized = item.skus[this.col_index].customize;
          this.skus = item.skus[this.col_index];
        } else {
          skuid = item.skus[0].id;
          stock = item.skus[0].stock;
          customized = item.skus[0].customize;
          this.skus = item.skus[0];
        }

        if (count > stock) {
          this.$bvToast.toast(this.$t("message.Insufficient_quantity"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }

        if (this.access_token == "" && this.lan == "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }
        if (customized) {
          this.showModal();
          return;
        }

        dispatch("add_to_cart", {
          skuid,
          count,
          customized,
          customizedesc
        }).then(data => {
          if (data.result) {
            this.$router.push({
              name: "enOrderLsPre"
            });
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
            }
          }
        });
      },
      add_to_cart_custom(dispatch, skuid, count, customized, texts) {
        if (count < 1) {
          this.$bvToast.toast(this.$t("message.Insufficient_quantity"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }

        if (this.access_token == "" && this.lan == "cn") {
          this.$bvToast.toast(this.$t("message.Please_Log_In"), {
            title: ``,
            toaster: "b-toaster-bottom-center",
            solid: true,
            variant: "danger",
            appendToast: false
          });
          return;
        }
        if (this.selected == 1 && this.text == "") {
          return;
        }

        let customizedesc = texts;
        if (this.selected == 0) {
          customizedesc = "";
        }
        dispatch("add_to_cart", {
          skuid,
          count,
          customized,
          customizedesc
        }).then(data => {
          if (data.result) {
            if (this.is_now == 0) {
              this.getShopCarLs();
              this.hideModal();
            } else {
              this.$router.push({
                name: "enOrderLsPre"
              });
            }
          } else {
            if (data.status == "logout") {
              this.showToast(this.$t("message.You_logout"));
              this.logout();
              this.hideModal();
            }
          }
        });
      }
    }),
    selCol(itm, idx) {
      itm.selectIndex = idx;
      this.sku = itm;
      this.col_index = idx;
    },
    hideModal() {
      this.$refs["modal-custom"].hide();
    },
    showModal() {
      this.$refs["modal-custom"].show();
    },
    showToast(msg) {
      this.$bvToast.toast(msg, {
        title: ``,
        toaster: "b-toaster-bottom-center",
        solid: true,
        variant: "danger",
        appendToast: false
      });
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("store");
      localStorage.removeItem("usrname");
      localStorage.removeItem("usrid");
      this.$store.state.usr = "";
      this.$store.state.usrid = "";
      this.$store.state.access_token = "";
      this.$store.state.items_cart = [];
    },
    toHome() {
      let nameHome = this.lan + "home";
      this.$router.replace({
        name: nameHome
      });
    },
  }
};
</script>